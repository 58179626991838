.slotMachine {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	&.small {
		.streams {
			margin: 0;
		}
	}
	&.mobileLandscape {
		.streams {
			height: 80%;
			margin: 0;
			display: flex;
			justify-content: space-between;
		}
	}
	.streams {
		min-height: 100px;
		margin: 0 10px;
		background: rgba(0, 0, 0, 0.48);
		display: flex;
		gap: 1px;
		position: relative;
		//align-items: center;
		//justify-content: center;
		flex: none;
		.switch {
			width: 64px;
			height: 64px;
			background: black;
			position: absolute;
			right: 2vw;
			top: 2vw;
			pointer-events: all;
			cursor: pointer;
		}
		.camera {
			width: 32px;
			height: 32px;
			position: absolute;
			display: flex;
			flex-direction: column;
			gap: 24px;
			left: 2vw;
			top: 2vw;
			filter: drop-shadow(2px 4px 6px black);
			cursor: pointer;
			img {
				pointer-events: all;
			}
		}

		iframe {
			border: 0;
			overflow: hidden;
		}
	}
}