.stream {
	//width: 100%;
	height: auto;
	background: rgba(0, 0, 0, 0.32);
	overflow: hidden;
	position: relative;
	video {
		height: 100%;
		width: 100%;
		display: block;
		//object-fit: contain;
		object-fit: fill;
	}
	.preloader {
		width: 100%;
		height: 100%;
		display: flex;
		justify-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		img {
			width: 64px;
		}
	}

	.sensor {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		pointer-events: all;
	}

	.debug {
		position: absolute;
		color: #c39903;
		filter: drop-shadow(1px 1px 1px black);
		background: rgba(0, 0, 0, 0.51);
		padding: 4px;
	}
}
