.remote {
	width: 100%;
	border-radius: 10px;
	background-color: #191919;
	display: flex;
	justify-content: space-around;
	gap: clamp(10px, 2vw, 24px);
	padding: clamp(4px, 2vh, 24px) clamp(8px, 2vw, 24px);
	color: #8c8c8c;
	text-wrap: nowrap;
	text-transform: uppercase;
	z-index: 99;
	&.medium {

	}
	&.small {
		border-radius: 0;
		flex-direction: column-reverse;
		align-items: center;
		height: 100%;
		justify-content: start;
		gap: 3vh;
		.group {
			gap: clamp(12px, 3vw, 32px);
			padding: 1vh;
		}
		.start {
			padding: clamp(16px, 5vw, 32px)!important;
			.label {
				font-size: clamp(8px, 5vw,20px);
			}
		}
		.line {
			border-radius: 3vw!important;
			aspect-ratio: 1/1!important;
			.label { display: none }
		}
		.select, .info, .auto {
			border-radius: 3vw!important;
			height: 10vw;
			aspect-ratio: 2/1!important;
			.label {
				font-size: clamp(8px, 1.5vw,20px);
				line-height: 1;
			}
		}
		.min, .max {
			padding: 3vw!important;
			.label {
				font-size: clamp(8px, 1.5vw,20px);
				line-height: 1;
			}
		}
	}
	&.mobileLandscape {
		height: 90px;
		border-radius: 0;
		gap: clamp(6px, 1vw, 24px);
		align-items: start;
		.group {
			gap: clamp(12px, 1vw, 32px);
			padding: 1vh;
		}
		.start {
			padding: clamp(8px, 1.3vw, 16px)!important;
			margin-top: -15%;
			.label {
				font-size: clamp(8px, 5vw,20px);
			}
		}
		.line {
			width: 4vw;
			height: 4vw!important;
			border-radius: 1vw!important;
			aspect-ratio: 1/1!important;
			padding: 8px!important;
			.label { display: none }
		}
		.select, .info, .auto {
			width: clamp(16px, 7vw, 64px)!important;
			border-radius: 1vw!important;
			height: 12vh;
			padding: 1vw!important;
			aspect-ratio: 1/1!important;
			.label {
				font-size: clamp(8px, 1vw,20px);
				line-height: 1!important;
			}
		}
		.min, .max {
			padding: 1.6vw!important;
			margin-top: -10%;
			.label {
				font-size: clamp(8px, 1.5vw,20px);
				line-height: 1;
			}
		}
	}

	.group {
		display: flex;
		gap: clamp(4px, 1.5vw, 20px);
		align-items: center;
		.button {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: center;
			text-align: center;
			gap: clamp(4px, 1vw, 12px);
			line-height: clamp(4px, 150%, 24px);
			padding: clamp(4px, 2vw, 12px);
			border: solid 1px #191919;
			background-image: linear-gradient(315deg, #191919, #252525);
			box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, .2), -5px -5px 10px 0 rgba(37, 37, 37, .5), inset 3px 3px 6px 0 #252525;
			border-radius: clamp(4px, 1vw, 15px);
			aspect-ratio: 1;
			pointer-events: all;
			cursor: pointer;
			&:active {
				box-shadow: inset 3px 3px 6px 0 rgba(14, 14, 14, .75), inset -3px -3px 6px 0 rgba(37, 37, 37, .5);
				background-image: linear-gradient(325deg, #191919 100%, #191919);
				p, img {
					opacity: .3;
				}
			}
			&.light {
				text-shadow: 0 0 2px rgba(247, 197, 0, .8), 0 0 4px rgba(255, 213, 0, .5);
				color: #f7c500;
				&.start {
					color:black!important;
					filter: drop-shadow(0 0 8px rgba(247, 197, 0, .46));
					box-shadow: 5px 5px 25px 0 rgba(255, 213, 0, .25), -5px -5px 25px 0 rgba(255, 213, 0, .25);
				}
			}
			.label {}
			.title {}
		}
	}

	.auto {
		border-radius: 50%!important;
		padding: clamp(4px, 2vw, 32px)!important;
	}
	.select {}
	.info {}
	.line {
		//height: clamp(48px, 10vw, 85px);
		height: max(48px, min(10vw, 85px))!important;
		aspect-ratio: 1/1.5!important;
	}

	.l {
		position: relative;
		&:after {
			content: "";
			width: clamp(2px, 40%, 40%);
			height: clamp(2px, 5%, 40%);
			background: #333334;
			position: absolute;
			border-radius: 5px;
		}
	}
	.c {
		border-radius: 50%!important;
		.title {
			font-weight: bold;
			opacity: .2;
		}
	}
	.min {
		@extend .c, .l;
		&:after { background: #d83749; }
	}
	.max {
		@extend .c, .l;
	}
	.start {
		@extend .c;
		//padding: clamp(4px, 1.5vw, 16px)!important;
		padding: max(4px, min(1.5vw, 16px))!important;
		color: black!important;

		border: solid 2px #c39903!important;
		background-image: linear-gradient(315deg, #edc202, #9a6a05)!important;
		box-shadow: 5px 5px 10px 0 rgba(236, 194, 1, 0.19), -5px -5px 10px 0 rgba(204, 163, 3, 0.26), inset 3px 3px 6px 0 #a17205 !important;
		justify-content: center!important;

		&:active {
			transform: scale(.95);
		}

		.label {
			font-size: clamp(8px, 2vw,20px);
			font-weight: bold;
			opacity: .7!important;
			letter-spacing: clamp(2px, .3vw, 7px);
		}
		.title {
			opacity: .4!important;
		}
	}

	svg {
		width: clamp(20px, 2vw, 32px);
		pointer-events: all;
		cursor: pointer;
	}
}
