* {
	box-sizing: border-box;
	pointer-events: none;
	user-select: none;
	margin: 0;
	font-weight: 400;
	font-family: Roboto, sans-serif;
	font-size: clamp(8px, 1.1vw, 10px);

	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}

html {
	height: 100vh;
	background: url("./img/bg.jpg") #1a1a1a center no-repeat;
	background-size: cover;
	box-sizing: border-box;
	color: gainsboro;
	font-family: "Roboto Mono", serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	margin: 0;
}

.app {
	width: 100%;
	min-width: 320px;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: NunitoRegular, serif;
	box-sizing: border-box;
	overflow: hidden;
	padding: 1vw;
	&.small {
		align-items: start;
		padding: 0;
		.content {
			height: 100%;
			max-width: 100%;
		}
	}
	&.mobileLandscape {
		align-items: start;
		padding: 0;
		.content {
			height: 100%;
			max-width: 100%;
		}
	}

	.content {
		width: 100%;
		max-width: 1200px;
		overflow: hidden;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	.isIOS {
		position: fixed;
		top: 0;
	}

	.ios_scroll_crunch {
		width: 1px;
		height: 999999px;
	}
}

:global(.scrollbar) {
	-ms-overflow-style: scrollbar; /* IE and Edge */
	scrollbar-width: thin; /* Firefox */
	scrollbar-color: #525457 #3f4144;

	&::-webkit-scrollbar {
		display: block; /* Chrome, Safari and Opera */
	}
}
