.header {
	width: 100%;
	height: 50px;
	border-radius: 10px;
	background-color: #191919;
	display: flex;
	justify-content: space-between;
	padding: 14px 18px;
	&.small {
		border-radius: 0!important;
	}
	img {
		width: 24px;
		pointer-events: all;
		cursor: pointer;
	}
}
