.messages {
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	background: rgba(0, 0, 0, .3);
	backdrop-filter: blur(2px);
	z-index: 99;
	.roll {
		width: 100%;
		background: rgba(0, 0, 0, .6);
		//padding: clamp(8px, 2vh, 16px) clamp(12px, 5vw, 20px); // Not work in iphone 6
		padding: max(8px, min(2vh, 16px)) max(12px, min(5vw, 20px));
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: clamp(8px, 2vh, 16px);
		.message {
			width: 100%;
			max-width: 420px;
			background: #000000;
			border-radius: 1vw;
			padding: clamp(4px, 3vh, 16px) clamp(12px, 3vw, 20px);
			position: relative;
			display: flex;
			flex-direction: column;
			gap: clamp(4px, 3vh, 16px);
			img {
				position: absolute;
				top: clamp(12px, 3vw, 20px);
				right: clamp(12px, 3vw, 20px);
				cursor: pointer;
				pointer-events: all;
			}
			.buttons {
				width: 100%;
				display: flex;
				justify-content: space-between;
				p {
					padding: clamp(4px, 1vw, 8px);
					background: #3f4144;
					cursor: pointer;
					pointer-events: all;
				}
			}
		}
	}
}
